import React from "react";
import { useTranslation } from "react-i18next";
import { Disclosure } from "clutch/src/Accordion/Accordion.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { Accordion, Card, InfoSection } from "@/feature-battles/Shared.jsx";
import GemIcon from "@/inline-assets/event-gem.svg";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const BattlesEventScoring = () => {
  const {
    parameters: [id],
  } = useRoute();

  const state = useSnapshot(readState);
  const { eventMap } = state.battles;
  const event = eventMap[id];
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();

  if (event instanceof Error || (isLoaded && event.missions.length === 0))
    return (
      <Card className="flex justify-center">
        <ErrorComponent
          description={t("battles:error.noScoringData", "No Scoring Data.")}
        />
      </Card>
    );

  return (
    <>
      <Card className="type-subtitle1">
        {t("battles:event.scoring", "Scoring")}
      </Card>
      <Accordion>
        {event.missions.map((mission, index) => (
          <Disclosure key={index}>
            <Disclosure.Button className="type-subtitle1 flex justify-between">
              <ScoringItem {...mission} />
            </Disclosure.Button>
            <Disclosure.Panel>{mission.description}</Disclosure.Panel>
          </Disclosure>
        ))}
      </Accordion>
    </>
  );
};

export const ScoringItem = ({ title, blitzPointValue, criteria }) => {
  const { t } = useTranslation();

  const missionPoints = blitzPointValue ?? 0;
  const criteriaPoints = criteria[0]?.params?.pointsPerAmount ?? 0;
  const scoreHintText =
    missionPoints & criteriaPoints
      ? t(
          "battles:scoring.both",
          "+{{missionPoints, number}} + {{criteriaPoints, number}}",
          { missionPoints, criteriaPoints },
        )
      : missionPoints
        ? t("battles:scoring.missionPoints", "+{{missionPoints, number}}", {
            missionPoints,
          })
        : t("battles:scoring.criteriaPoints", "+{{criteriaPoints, number}}", {
            criteriaPoints,
          });
  return (
    <div className="flex justify-between w-full">
      {title}
      <div className="flex align-items">
        {scoreHintText}
        <GemIcon />
      </div>
    </div>
  );
};

export const SidebarScoringInfo = ({ event }) => {
  const { t } = useTranslation();

  return (
    <InfoSection title={t("battles:scoring", "Scoring")}>
      {event.missions.map((mission, index) => (
        <Card key={index}>
          <ScoringItem {...mission} />
        </Card>
      ))}
    </InfoSection>
  );
};

export default BattlesEventScoring;
