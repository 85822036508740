import { css, styled } from "goober";

export const Leaderboard = styled("div")`
  --lb-bg: var(--shade7);
  table {
    width: 100%;
    border-spacing: 0 var(--sp-0_5);

    tbody {
      & > :last-child {
        & > :first-child {
          border-bottom-left-radius: var(--br);
        }
        & > :last-child {
          border-bottom-right-radius: var(--br);
        }
      }
      & > :first-child {
        & > :first-child {
          border-bottom-left-radius: 0;
        }
        & > :last-child {
          border-bottom-right-radius: 0;
        }
      }
    }

    tfoot {
      & > :last-child {
        & > * {
          border-bottom-left-radius: var(--br);
          border-bottom-right-radius: var(--br);

          text-align: center;
        }
      }
    }
  }

  caption {
    border-top-left-radius: var(--br);
    border-top-right-radius: var(--br);

    padding: var(--sp-4_5) var(--sp-6);
    background-color: var(--lb-bg);

    text-align: start;
  }

  tr {
    --rank-color-hsl: var(--shade5-hsl);
    --rank-color: hsl(var(--rank-color-hsl) / 1);
    --rank-color-bg: hsl(var(--rank-color-hsl) / 0.15);
    --bg-gradient: 270deg, var(--shade7), var(--rank-color-bg) 100%;
    --row-bg: var(--lb-bg);
    --rank-flair-display: none;

    &[data-rank="1"] {
      --rank-color-hsl: var(--rank-gold-hsl);
      --rank-flair-display: block;
      --row-bg: linear-gradient(var(--bg-gradient));
    }
    &[data-rank="2"] {
      --rank-color-hsl: var(--rank-silver-hsl);
      --rank-flair-display: block;
      --row-bg: linear-gradient(var(--bg-gradient));
    }
    &[data-rank="3"] {
      --rank-color-hsl: var(--rank-bronze-hsl);
      --rank-flair-display: block;
      --row-bg: linear-gradient(var(--bg-gradient));
    }

    &[data-highlighted="true"] {
      --rank-flair-display: none;
      --row-bg: hsl(var(--turq-hsl) / 0.15);
    }

    position: relative;
    width: 100%;
    background: var(--row-bg);

    th {
      white-space: nowrap;
    }

    th,
    td {
      height: var(--sp-13);
      padding-right: var(--sp-2);

      text-align: start;
    }

    & > :first-child {
      padding-left: var(--sp-6);
    }

    & > :nth-child(-1) {
      padding-right: var(--sp-6);
    }

    .user-avatar {
      border-radius: 50%;
      border: solid var(--sp-0_5) var(--rank-color);

      width: var(--sp-9);
      height: var(--sp-9);
      padding: var(--sp-0_5);

      & > img {
        border-radius: 100%;
        width: var(--sp-7);
        height: var(--sp-7);
      }
    }

    .right-align {
      text-align: end;
      & > div {
        justify-content: end;
      }
    }

    &::after {
      display: var(--rank-flair-display);
      content: "";

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: var(--sp-1);
      background-color: var(--rank-color);
    }
  }
`;

export const cssPointBreakdownList = () => css`
  & .item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: var(--sp-2);

    padding: var(--sp-1) var(--sp-2);
    color: var(--shade2);

    & .ra {
      text-align: right;
    }
  }
`;
