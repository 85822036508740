import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { useParticipantInfo } from "@/feature-battles/hooks.mjs";
import * as Styled from "@/feature-battles/Leaderboard.style.jsx";
import { Card, CardList } from "@/feature-battles/Shared.jsx";
import {
  getCriteriaDetails,
  getProfileInfo,
} from "@/feature-battles/util-game.mjs";
import InfoIcon from "@/inline-assets/blitz-info-border.svg";
import GemIcon from "@/inline-assets/event-gem.svg";
import ExclamationIcon from "@/inline-assets/exclamation-mark.svg";
import { classNames } from "@/util/class-names.mjs";
import { formatToFixedNumber } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const WidgetContainer = styled("div")`
  padding: var(--sp-8) 0;

  > svg {
    height: var(--sp-12);
    display: block;
    margin: auto;
  }

  > span {
    min-height: auto;
  }
`;

export function Leaderboard({ eventId, highlightProfileId = null }) {
  const { t } = useTranslation();

  const {
    battles: { eventMap, eventParticipantInfo, userOptIns },
  } = useSnapshot(readState);
  const event = eventMap[eventId];
  const participantInfo = useParticipantInfo(
    eventId,
    eventParticipantInfo,
    userOptIns,
  );

  if (participantInfo.isLoading || !event)
    return (
      <WidgetContainer>
        <LoadingSpinner />
      </WidgetContainer>
    );
  if (participantInfo.isError || event instanceof Error) {
    return (
      <WidgetContainer>
        <ExclamationIcon />
      </WidgetContainer>
    );
  }

  const leaderboard = participantInfo.leaderboard.filter((item) => item.score);

  return (
    <Styled.Leaderboard>
      <table>
        <caption className="type-subtitle1">
          {t("battles:leaderboard.title", "Leaderboard")}
        </caption>
        <thead>
          <LeaderboardHeaderRow
            className={!leaderboard.length ? "visually-hidden" : ""}
          />
        </thead>
        <tbody>
          {leaderboard.map((item, index) => (
            <LeaderboardRow
              key={index}
              rank={item.rank}
              score={item.score}
              game={event.game}
              missionProgress={item.missionProgress}
              missions={event.missions}
              profile={item.profile}
              isHighlighted={item.profile.accountId === highlightProfileId}
              className="leader-row"
            />
          ))}
        </tbody>
        {!leaderboard.length && (
          <tfoot>
            <tr className="type-body2">
              <td>
                {t(
                  "battles:leaderboard.noItems",
                  "No one has placed yet. Play some games to be the first!",
                )}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </Styled.Leaderboard>
  );
}

export function SubsectionLeaderboard({ missions, items, highlightProfileId }) {
  const { t } = useTranslation();

  return (
    <Styled.Leaderboard>
      <table>
        <caption className="visually-hidden">
          {t("battles:leaderboard.subsectionTitle", "Your Ranking")}
        </caption>
        <thead>
          <LeaderboardHeaderRow className="visually-hidden" />
        </thead>
        <tbody>
          {items.map((item, index) => (
            <LeaderboardRow
              key={index}
              rank={item.rank}
              score={item.score}
              game={item.game}
              missionProgress={item.missionProgress}
              missions={missions}
              profile={item.profile}
              isHighlighted={item.profile.accountId === highlightProfileId}
            />
          ))}
        </tbody>
      </table>
    </Styled.Leaderboard>
  );
}

function LeaderboardHeaderRow({ className }) {
  const { t } = useTranslation();

  return (
    <tr className={`type-caption ${className ?? ""}`}>
      <th>{t("battles:leaderboard.headers.rank", "Rank")}</th>
      <th>{t("battles:leaderboard.headers.username", "Username")}</th>
      <th className="right-align">
        {t("battles:leaderboard.headers.points", "Points Earned")}
      </th>
      <th className="visually-hidden">
        {t("battles:leaderboard.headers.completions", "Missions Completed")}
      </th>
    </tr>
  );
}

function LeaderboardRow({
  rank,
  score,
  game,
  profile,
  isHighlighted,
  missionProgress,
  missions,
}) {
  const { t } = useTranslation();

  const { avatarUri, name, profileUri } = getProfileInfo({
    game,
    profile,
  }) ?? {
    name: t("battles:leaderboard.userNameError", "Battles Participant"),
    avatarUri: undefined,
    profileUri: undefined,
  };

  const missionCompletionMarkdown = useMemo(() => {
    if (!(missions && missionProgress)) return null;
    return renderToStaticMarkup(
      <PointBreakdown
        gameSymbol={game}
        missionProgress={missionProgress}
        missions={missions}
      />,
    );
  }, [game, missions, missionProgress]);

  const LinkTag = profileUri ? "a" : "div";
  return (
    <tr data-rank={rank} data-highlighted={isHighlighted}>
      <td className="type-subtitle2">
        {t("battles:leaderboard.rank", "{{rank, number}}", { rank })}
      </td>
      <td>
        <LinkTag
          href={profileUri}
          className="type-subtitle2 flex gap-1 align-center"
        >
          {avatarUri && (
            <div className="user-avatar">
              <img
                src={avatarUri}
                alt={t("battles:leaderboard.avatarAlt", "User avatar")}
              />
            </div>
          )}
          {name}
        </LinkTag>
      </td>
      <td className="type-subtitle2 right-align">
        <div className="flex gap-1">
          {score}
          <GemIcon />
        </div>
      </td>
      <td className="type-subtitle2">
        {missionCompletionMarkdown && (
          <div data-place="bottom" data-tooltip={missionCompletionMarkdown}>
            <InfoIcon />
          </div>
        )}
      </td>
    </tr>
  );
}

function PointBreakdown({ gameSymbol, missions, missionProgress }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const breakdowns = useMemo(() => {
    return missions
      .map(({ id, criteria }) => {
        const progress = missionProgress[id];
        const criteriaDetails =
          criteria?.[0] &&
          getCriteriaDetails({ game: gameSymbol, criteria: criteria[0] });
        if (!criteriaDetails) return null;

        const points = progress?.points ?? 0;
        const completions = points / criteriaDetails.points;
        return {
          id,
          mapName: criteriaDetails.localizedMap,
          displayName: criteriaDetails.displayName,
          completions,
          points,
        };
      })
      .filter(Boolean);
  }, [gameSymbol, missions, missionProgress]);

  return (
    <CardList className={Styled.cssPointBreakdownList()} variant="table">
      {breakdowns.map(({ id, mapName, displayName, completions, points }) => (
        <Card key={id} className="type-mini item">
          <span>
            {mapName
              ? t(
                  "battles:leaderboard.breakdown",
                  "{{mapName}} {{displayName}}",
                  {
                    mapName,
                    displayName,
                  },
                )
              : displayName}
          </span>
          <span {...classNames("ra", completions && "shade0")}>
            {formatToFixedNumber(language, completions, 0)}
          </span>
          <span
            {...classNames("flex align-center justify-end", points && "shade0")}
          >
            {formatToFixedNumber(language, points, 0)}
            <GemIcon />
          </span>
        </Card>
      ))}
    </CardList>
  );
}
